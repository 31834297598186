<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>

    <el-button style="margin-bottom: 10px" icon="el-icon-plus" plain type="primary" size="mini" @click="handleAdd">新增</el-button>
    <el-table :data="list">
      <el-table-column prop="id" label="ID" min-width="100"> </el-table-column>
      <el-table-column prop="id" label="图片" min-width="120">
        <template slot-scope="{ row }">
          <el-image style="width: 100px; height: 60px; display: block" :src="row.banner_url" fit="cover"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" min-width="120">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 20" type="success" size="small">发布</el-tag>
          <el-tag v-if="row.status == 10" type="danger" size="small">下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序" min-width="120"> </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>
          <el-link :underline="false" type="danger" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
    <!-- 新增编辑 -->
    <el-dialog :title="formData.id ? '编辑' : '新增'" :visible.sync="show_update" width="width">
      <el-form ref="form" :model="formData" label-width="140px">
        <el-form-item class="url-box" label="图片：" prop="banner_url" :rules="rules">
          <UploadImg :url.sync="formData.banner_url"></UploadImg>
        </el-form-item>
        <el-form-item label="状态：" prop="status" :rules="rules">
          <el-radio-group v-model="formData.status">
            <el-radio :label="20">发布</el-radio>
            <el-radio :label="10">下架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input-number v-model="formData.sort" :step="1" :min="1" size="small" step-strictly></el-input-number>
          <span style="font-size: 12px; color: #00000073; margin-left: 10px">数字越小越靠前</span>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_update = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { getListAPI, getDetailAPI, addAPI, editAPI, delAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import UploadImg from '@/components/qiniu-uploader/uploader-img.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { UploadImg },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_update: false,
      list: [],
      searchData: {
        page: 1,
        page_size: 10
      },

      total: 0,
      formData: {
        banner_url: '',
        content: '',
        status: 20,
        sort: 1
      }
    }
  },

  activated() {},
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      let params = { ...this.searchData }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    // del
    async handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 新增
    handleAdd() {
      this.show_update = true
      this.$nextTick(() => {
        this.formData = {
          banner_url: '',
          content: '',
          status: 20,
          sort: 1
        }
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({ id }) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.getList()
              this.show_update = false
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.getList()
              this.show_update = false
            })
          }
        }
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .tip {
    font-size: 12px;
    color: #00000073;
    line-height: 25px;
    .link {
      color: #1890ff;
      cursor: pointer;
    }
  }
}
</style>
<style scoped lang="scss">
.url-box /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 278px !important;
    height: 100px !important;
    line-height: 100px !important;
    text-align: center;
  }
  .avatar {
    width: 278px !important;
    height: 100px !important;
    display: block;
  }
}
</style>

import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/api/company/banner/list`,
    method: 'get',
    params
  })
}

// 详情
export function getDetailAPI(id) {
  return http({
    url: `/api/company/banner/detail`,
    method: 'get',
    params: { id }
  })
}
// add
export function addAPI(data) {
  return http({
    url: `/api/company/banner/add`,
    method: 'post',
    data
  })
}
// 编辑
export function editAPI(data) {
  return http({
    url: `/api/company/banner/edit`,
    method: 'post',
    data
  })
}
// del
export function delAPI(id) {
  return http({
    url: `/api/company/banner/del`,
    method: 'post',
    data: { id }
  })
}
